<template>
  <button
    class="app-btn"
    :class="{ 'app-btn--alignRight': align === 'right' }"
    :disabled="disabled"
    :type="type"
  >
    <div class="app-btn__contents">
      <slot />
    </div>
  </button>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    disabled: { type: Boolean, default: false },
    type: { type: String, required: false },
    align: { type: String, required: false },
  },
};
</script>

<style lang="scss" scoped>
.app-btn {
  width: 100%;
  display: block;
  background: rgba(136, 136, 136, 1) 0 0 no-repeat padding-box;
  color: #fff;
  font: normal normal 600 25px/30px Montserrat;
  padding: 16px 32px;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover,
  :active {
    background: var(--buzz-charging-yellow);
    color: var(--buzz-charging-dark-blue);
  }

  &__contents {
    font-size: 18px;
  }
  &--alignRight {
    margin-left: auto;
    margin-right: 0 !important;
  }
}
</style>
